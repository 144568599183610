@import './assets/huurstunt/scss/utilities/common';

.registration {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;

  .suggestion-message {
    margin-bottom: 0.75rem;
    margin-top: -0.75rem;
  }

  .top {
    min-height: 315px;
    margin-top: 20px;
  }

  p.howto {
    margin-top: 2rem;
  }

  h2 {
    margin-top: 1rem;
  }

  .bottom {
    .register {
      text-align: center;
    }

    [type=submit] {
      margin-top: 0;
    }
  }

  p.condition {
    font-size: 0.9rem;
  }
}
